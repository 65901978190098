import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { InstanceService } from '../services/instance.service';
import { ContainerModel } from '../models/container.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  private containersCollection:any;
  private containersObj:any;

  private containers:any;

  constructor(
    private afs: AngularFirestore,
    private instance: InstanceService
  ) {
    this.containersCollection = this.afs.collection('containers', ref => ref.where('active','==',true));
    this.containersObj = this.containersCollection.snapshotChanges()
      .subscribe(data=>{

        this.containers = data.map(
          a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();

            return {id, ...data};
          }
        );

        //console.log('Containers:', this.containers);

        this.containersObj.unsubscribe();
      }, (error) => {
        console.log(error);
    });
  }

  getContainers () {
    let user = this.instance.getUser();
    let count = 0;
    let container:ContainerModel = null;

    if (this.containers && user.role == 'user' && user.access) {
      this.containers.forEach(function (value) {
        if (user.access[value.id]) {
          count++;
          container = value;
        }
      });
    }

    if (count) {
      this.instance.setContainer(container);
    }

    return this.containers;
  }

}
