import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { InstanceService } from '../../services/instance.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit {

  constructor(
    private auth: AuthService,
    public instance: InstanceService
  ) { }

  ngOnInit(): void {
  }


  logout() {
    this.auth.logout();
  }

}
