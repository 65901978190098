import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { InstanceService } from '../../services/instance.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html'
})
export class PanelComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public instance: InstanceService
  ) { }

  ngOnInit(): void {
    this.instance.initialize();
  }

  selectSection(option:string) {
    this.instance.setSection(option);
  }

  getSection() {
    return this.instance.getSection();
  }

  checkDenegate(section:string) {
    if (this.instance.getUser().config) {
      if (this.instance.getUser().config.denegate) {
        if (this.instance.getUser().config.denegate) {
          return this.instance.getUser().config.denegate[section];
        }
      }
    } else {
      return false;
    }
  }

  checkHide(section:string) {
    if (this.instance.getContainer(true)[section]) {
      if (this.instance.getContainer(true)[section]['hide']) {
        return true;
      }
    } else {
      return false;
    }
  }

}
