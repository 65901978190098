import { Component, OnInit } from '@angular/core';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html'
})
export class LeadsComponent implements OnInit {

  constructor(
    public lead: LeadService
  ) { }

  ngOnInit(): void {
    this.lead.initLeads();
  }

  toggleItem(item) {
    // show btn with id btnId in DOM
    item.showButton = !item.showButton;
  }

  isToday(date) {
    let today = new Date();
    return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()
  }

}
