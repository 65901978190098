<div class="login uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade cover" uk-height-viewport>
	<div class="uk-width-1-1">
		<div class="uk-container">
			<div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
				<div class="uk-width-1-1@m uk-animation-fade uk-animation-slide-bottom-small">
					<div class="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
            <div class="uk-text-center">
              <img src="assets/topic_logo.svg" alt="Topic" title="Topic" width="70" height="70" />
            </div>
						<h3 class="uk-card-title uk-text-center">Topic Data Platform <small>(beta)</small></h3>
						<form *ngIf="!isLogged()" (ngSubmit)="login(f)" #f="ngForm">
							<div class="uk-margin">
								<div class="uk-inline uk-width-1-1">
									<span class="uk-form-icon" uk-icon="icon: mail"></span>
									<input class="uk-input uk-form-large" type="email" name="email" placeholder="Correo electrónico"
										[(ngModel)]="user.email"
										required
										email
									>
								</div>
								<div *ngIf="f.submitted && f.controls['email'].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
							    <p><small>El correo electrónico es obligatorio.</small></p>
								</div>
							</div>

							<div class="uk-margin">
								<div class="uk-inline uk-width-1-1">
									<span class="uk-form-icon" uk-icon="icon: lock"></span>
									<input class="uk-input uk-form-large" type="password" name="password" placeholder="Contraseña"
										[(ngModel)]="user.password"
										minlength="6"
										required
									>
								</div>
								<div *ngIf="f.submitted && f.controls['password'].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
							    <p><small>La contraseña debe tener al menos 6 caracteres.</small></p>
								</div>
							</div>

							<div *ngIf="error" class="uk-alert-danger" uk-alert>
								<a class="uk-alert-close" uk-close></a>
								<p>No se encuentra una coincidencia entre el correo eléctronico y la contraseña insertada.</p>
							</div>

							<div class="uk-margin">
								<button class="uk-button uk-button-primary uk-button-large uk-width-1-1">Iniciar sesión</button>
							</div>
						</form>

						<div *ngIf="isLogged()" >
							<div class="uk-alert-success" uk-alert>
								<a class="uk-alert-close"></a>
								<p>Sesión iniciada.</p>
							</div>
							<div class="uk-margin">
								<a routerLink="/panel" class="uk-button uk-button-primary uk-button-large uk-width-1-1">Continuar</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
