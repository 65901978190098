import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../services/container.service';
import { InstanceService } from '../../services/instance.service';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html'
})
export class ContainersComponent implements OnInit {

  constructor(
    public container: ContainerService,
    public instance: InstanceService
  ) { }

  ngOnInit(): void { }

  getUsers(users) {
    return Object.values(users).length;
  }

}
