import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';
import { ContainerModel } from '../models/container.model';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  private userDoc:AngularFirestoreDocument<any>;
  private userObj:any;

  private container:string = null;
  private containerData:ContainerModel = null;
  private section:string = 'control';

  constructor(
        private afs: AngularFirestore,
        private auth: AuthService
  ) { }

  initialize () {
    if(localStorage.getItem('auth_uid')) {
      if (!this.getUser()) {
        console.log(localStorage.getItem('auth_uid'));

        this.userDoc = this.afs.doc('u/' + localStorage.getItem('auth_uid'));
        this.userObj = this.userDoc.valueChanges()
          .subscribe(data=>{
            console.log('User:', data);
            this.setUser(data);
            this.processUser();

            this.userObj.unsubscribe();
          }, (error) => {
            console.log(error);
        });
      }else {
        this.processUser();
        console.log('(LOCAL) User:', this.getUser());
      }
    }else {
      this.auth.logout();
    }
  }

  processUser () {
    if (this.getUser()) {
      if (this.getUser().role == 'user') {
        this.setSection('containers');
      }
    }
  }

  setUser (user: Object) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser () {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'));
    }else {
      return null;
    }
  }

  setContainer (container: ContainerModel) {
    console.log('(SET) Container:',container);

    this.container = container.id;
    this.containerData = container;

    if (this.containerData.dashboards) {
      this.setSection('dashboard');
    } else {
      this.setSection('leads');
    }

  }

  getContainer (data?:boolean) : any {
    if (data) {
      return this.containerData;
    } else {
      return this.container;
    }
  }

  updateContainer () {
    console.log('Container:',this.container);
    console.log('Container Data:',this.containerData);

    this.afs.collection("containers").doc(this.container).set(this.containerData);
  }

  setSection (section: string) {
    this.section = section;
  }

  getSection () {
    return this.section;
  }

  addDashboard (data: Object) {
    if (!this.containerData['dashboards']) {
      this.containerData['dashboards'] = [];
    }
    this.containerData['dashboards'].push(data);
  }
}
