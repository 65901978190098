<div *ngIf="lead.getLeads() && !lead.loadingPage">
  <div (click)="toggleItem(item)" *ngFor="let item of lead.getLeads(); index as i;" class="uk-card uk-card-hover uk-width-expand uk-margin-bottom uk-animation-slide-left-small lead">
      <div *ngIf="i !== 50">
        <div class="uk-card-header">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-auto">
                    <span *ngIf="item.data.tracking_source == 'WhatsApp'" uk-icon="icon: whatsapp; ratio: 2"></span>
                    <span *ngIf="item.data.tracking_source == 'Facebook'" uk-icon="icon: facebook; ratio: 2"></span>
                    <span *ngIf="!item.data.tracking_source || item.data.tracking_source == 'Web Form'" uk-icon="icon: world; ratio: 2"></span>
                </div>
                <div class="uk-width-expand">
                    <div *ngIf="isToday(item.datetime.toDate())" class="uk-card-badge uk-label">Hoy</div>
                    <h3 class="uk-card-title uk-margin-remove-bottom"><span *ngIf="item.data.firstname">{{ item.data.firstname }}</span> <span *ngIf="item.data.lastname"> {{ item.data.lastname }}</span> <span *ngIf="item.data.fullname">{{ item.data.fullname }}</span></h3>
                    <p class="uk-text-meta uk-margin-remove-top">
                      <span *ngIf="item.datetime" class="uk-margin-small-right">{{ item.datetime.toDate()|date:'dd/MM/yyyy h:mm:ss a' }}</span>
                      <a *ngIf="item.data.tracking_title && item.data.tracking_url" href="{{ item.data.tracking_url }}" class="uk-link-text" target="blank">{{ item.data.tracking_title }}</a>
                      <span *ngIf="item.data.tracking_title && !item.data.tracking_url">{{ item.data.tracking_title }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="item.showButton">
          <div *ngIf="item.data.message" class="uk-card-body">
              <p>{{ item.data.message }}</p>
          </div>
          <div class="uk-card-footer">
            <div class="uk-column-1-2">
                <p *ngIf="item.data.dni"><span uk-icon="info"></span> {{ item.data.dni }} <small>(DNI)</small></p>
                <p *ngIf="item.data.cuit"><span uk-icon="info"></span> {{ item.data.cuit }} <small>(CUIT)</small></p>
                <p *ngIf="item.data.email"><span uk-icon="mail"></span> {{ item.data.email }}</p>
                <p *ngIf="item.data.phone"><span uk-icon="receiver"></span> {{ item.data.phone }}</p>
                <p *ngIf="item.data.location"><span uk-icon="location"></span> {{ item.data.location }}</p>
                <p *ngIf="item.data.company"><span uk-icon="home"></span> {{ item.data.company }}</p>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div *ngIf="lead.getLeads().length == 51">
    <ul class="uk-pagination">
        <li *ngIf="lead.getPageNumber() > 0"><a (click)="lead.prevPage()"><span class="uk-margin-small-right" uk-pagination-previous></span> Anterior</a></li>
        <li class="uk-margin-auto-left"><a (click)="lead.nextPage()">Siguiente <span class="uk-margin-small-left" uk-pagination-next></span></a></li>
    </ul>
  </div>
</div>
