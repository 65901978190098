<nav class="uk-navbar-container uk-margin">
  <div class="uk-container">
  <div class="uk-navbar-left">
    <span class="uk-navbar-item uk-logo"><img src="assets/topic_logo.svg" class="uk-margin-small-right" alt="Topic" title="Topic" width="35" height="35" /> <small>Topic Data Platform</small></span>

    <div class="uk-navbar-item uk-navbar-right uk-visible@m">
      <button (click)="logout()" class="uk-button uk-button-default">Cerrar sesión <span uk-icon="sign-out"></span></button>
    </div>

  </div>
  </div>
</nav>
