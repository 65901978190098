<div *ngIf="instance.getUser().role == 'admin'">
    <div *ngFor="let item of container.getContainers()">
        <div class="uk-card uk-card-small uk-card-hover uk-card-body">
            <div *ngIf="item.access" class="uk-card-badge"><span uk-icon="icon: users"></span> {{ getUsers(item.access.user) }}</div>
            <h3 class="uk-card-title"><a (click)="instance.setContainer(item)" class="uk-link uk-text-emphasis">{{ item.name }} <span *ngIf="!item.name">{{ item.id }}</span></a></h3>
        </div>
    </div>
</div>

<div *ngIf="instance.getUser().role == 'user' && instance.getUser().access">
    <div *ngFor="let item of container.getContainers()">
        <div *ngIf="(instance.getUser().role == 'admin') || (instance.getUser().access[item.id])" class="uk-card uk-card-small uk-card-hover uk-card-body">
            <div *ngIf="item.access" class="uk-card-badge"><span uk-icon="icon: users"></span> {{ getUsers(item.access.user) }}</div>
            <h3 class="uk-card-title"><a (click)="instance.setContainer(item)" class="uk-link uk-text-emphasis">{{ item.name }} <span *ngIf="!item.name">{{ item.id }}</span></a></h3>
        </div>
    </div>
</div>
