import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState: any = null;

  constructor(
    private router: Router,
    public auth: AngularFireAuth
  ) {
    this.auth.authState.subscribe( authState => {
      this.authState = authState;
    });
  }

  login(user:UserModel) {
    this.auth.signInWithEmailAndPassword(user.email, user.password).then(data=>{
      localStorage.setItem('auth_uid', data.user.uid);
      console.log('User:',data);
      this.router.navigate(['panel']);
    }, (error) => {
      console.log('Error:',error);
      this.router.navigate(['login','error']);
    });
    return true;
  }

  logout() {
    localStorage.clear();
    this.auth.signOut();
    this.router.navigate(['login']);
  }

  isAuthenticated() {
    return this.authState !== null;
  }

  currentUserId() {
    return this.isAuthenticated ? this.authState.uid : null;
  }

  getUser() {
    if (!this.isAuthenticated ) {
      return [];
    }

    return [
      {
        id: this.authState.uid,
        displayName: this.authState.displayName,
        email: this.authState.email,
        phoneNumber: this.authState.phoneNumber,
        photoURL: this.authState.photoURL,
      }
    ];
  }

}
