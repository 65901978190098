import { Injectable } from '@angular/core';
import { InstanceService } from '../services/instance.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  private leadsCollection: AngularFirestoreCollection<any>;
  private leadsObj:any;

  private container:string = null;
  private leads:any = null;

  private firstItem:any;
  private lastItem:any;

  private page:number = 0;
  public loadingPage:boolean = true;

  constructor(
    private afs: AngularFirestore,
    public instance: InstanceService
  ) { }

  initLeads () {
    if (this.instance.getContainer()) {
      if (this.container != this.instance.getContainer()) {
        this.container = this.instance.getContainer();

        this.leadsCollection = this.afs.collection('containers/' + this.container + '/leads', ref => ref.orderBy('datetime','desc').limit(51));

        this.leadsObj = this.leadsCollection.valueChanges()
          .subscribe(data=>{
            this.leads = data;

            if (this.leads[0]) {
              this.firstItem = this.leads[0].datetime;
            }
            if (this.leads[50]) {
              this.lastItem = this.leads[50].datetime;
            }

            this.loadingPage = false;

            console.log('(INIT) Leads:', this.leads);
          }, (error) => {
            console.log(error);
        });
      }
    }
  }

  updateLeads (time: any, isLast: boolean) {
    if (isLast) {
      this.leadsCollection = this.afs.collection('containers/' + this.container + '/leads', ref => ref.orderBy('datetime','desc').startAt(time).limit(51));
    } else {
      this.leadsCollection = this.afs.collection('containers/' + this.container + '/leads', ref => ref.orderBy('datetime','desc').endAt(time).limitToLast(51));
    }


    this.leadsObj = this.leadsCollection.valueChanges()
      .subscribe(data=>{
        this.leads = data;

        if (this.leads[0]) {
          this.firstItem = this.leads[0].datetime;
        }
        if (this.leads[50]) {
          this.lastItem = this.leads[50].datetime;
        }

        this.loadingPage = false;

        console.log('(UPDATE) Leads:', this.leads);
      }, (error) => {
        console.log(error);
    });
  }

  getLeads () {
    return this.leads;
  }

  getPageNumber () {
    return this.page;
  }

  prevPage() {
    if (this.firstItem && this.page > 0) {
      this.loadingPage = true;
      this.page--;
      this.updateLeads(this.firstItem, false);
      console.log('Page:',this.page);
    }
  }

  nextPage() {
    if (this.lastItem) {
      this.loadingPage = true;
      this.page++;
      this.updateLeads(this.lastItem, true);
      console.log('Page:',this.page);
    }
  }
}
