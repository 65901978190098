import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InstanceService } from '../../services/instance.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html'
})
export class ConfigComponent implements OnInit {

  integrations = {
    send: [
      {id: 'email', title: 'Email', active: 'emails'},
      {id: 'google_analytics', title: 'Google Analytics', active: 'googleanalytics'},
      {id: 'doppler', title: 'Doppler', active: 'doppler'},
      {id: 'tecnom', title: 'Tecnom', active: 'tecnom'},
      {id: 'mailchimp', disabled: true, title: 'MailChimp'},
      {id: 'salesforce', disabled: true, title: 'Sales Force'},
      {id: 'pilot', disabled: true, title: 'Pilot'},
      {id: 'sirena', disabled: true, title: 'Sirena'},
      {id: 'tokko', disabled: true, title: 'Tokko Broker'}
    ],
    get: [
      {id: 'facebook_leads', title: 'Facebook Leads'},
      {id: 'google_leads', disabled: true, title: 'Google Leads'},
      {id: 'mercadopago', disabled: true, title: 'Mercado Pago'},
      {id: 'tiendanube', disabled: true, title: 'Tienda Nube'}
    ],
    datastudio: [
      {id: 'datastudio_facebook', title: 'Facebook Insights', active: 'datastudio_facebook'},
      {id: 'datastudio_mailchimp', disabled: true, title: 'Mailchimp Reports'}
    ]
  };

  private schema;
  config = "";
  dataset = "";
  private section = 'ui';
  private integrationTab = 'send';

  constructor (
    public instance: InstanceService,
    public configuration: ConfigService
  ) {
  }

  ngOnInit (): void {
    this.configuration.initConfig();
  }

  setSection (section: string) {
    this.section = section;
  }

  getSection () {
    return this.section;
  }

  setIntegrationTab (tab: string)  {
    this.integrationTab = tab;
  }

  getIntegrationTab ()  {
    return this.integrationTab;
  }

  getSchema () {
    if (this.configuration.getSchema()) {
      return this.configuration.getSchema();
    } else {
      return null;
    }
  }

  saveSchema (form: NgForm) {
    if (form.invalid || !form.dirty) {
      return;
    }

    this.configuration.updateSchema();
  }

  getKeys(item: string) {
    return Object.keys(item);
  }

  showItem (item) {
    if (item || item == '') {
      return true;
    } else {
      return false;
    }
  }

  deleteIntegration (integration: string, item?: string) {
    if (item) {
      delete this.configuration.getSchema().integrations[integration][item];
      if (this.getKeys(this.configuration.getSchema().integrations[integration]).length <= 0) {
        delete this.configuration.getSchema().integrations[integration];
      }
    } else {
      delete this.configuration.getSchema().integrations[integration];
    }
  }

  deleteField (field: string) {
    delete this.configuration.getSchema().fields[field];
  }

  addDataset(config:string, dataset?: string) {
    if (config == 'fields') {
      if (!this.configuration.getSchema()[config]) {
        this.configuration.getSchema()[config] = {};
      }
    } else {
      if (!this.configuration.getSchema().integrations[config]) {
        this.configuration.getSchema().integrations[config] = {};
      }
    }

    if (config == 'fields') {
      this.configuration.getSchema()[config][dataset] = "";
    } else if (config == 'emails') {
      this.configuration.getSchema().integrations[config][dataset] = {direction:''};
    } else if (config == 'googleanalytics') {
      this.configuration.getSchema().integrations[config] = {uid:'',tag:{category:'',action:'',label:''}};
    } else if (config == 'doppler') {
      this.configuration.getSchema().integrations[config][dataset] = {account:'',list:'',key:''};
    } else if (config == 'tecnom') {
      this.configuration.getSchema().integrations[config][dataset] = {subdomain:'',module:'',auth:{user:'',password:''}};
    } else if (config == 'datastudio_facebook') {
      this.configuration.getSchema().integrations[config][dataset] = {connector:'campaigns',ad_account:'',page:''};
    }
  }

  selectConfiguration (config: string) {
    this.config = config;
  }

  selectDataset (form: NgForm) {
    if (form.invalid) {
      return;
    }
    console.log(this.config);
    this.addDataset(this.config, this.dataset);

    this.dataset = "";
  }

}
