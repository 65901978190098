<div class="uk-column-1-2 uk-margin-bottom">
    <div>
      <ul *ngIf="instance.getUser().role == 'admin'" class="uk-iconnav" style="padding-top: 10px;">
          <li><a (click)="addDashboard()" title="Añadir" uk-icon="icon: plus"></a></li>
          <li><a *ngIf="instance.getContainer(true).dashboards" href="#edit" title="Editar" uk-icon="icon: file-edit" uk-toggle></a></li>
          <li><a (click)="deleteDashboard(true)" *ngIf="instance.getContainer(true).dashboards" href="#delete" title="Eliminar" uk-icon="icon: trash" uk-toggle></a></li>
      </ul>
    </div>
    <div *ngIf="instance.getContainer(true).dashboards" [className]="instance.getContainer(true).dashboards[1] ? '' : 'hide'">
      <form>
          <select (change)="setSelected($event.target.value)" class="uk-select">
              <option *ngFor="let dashboard of instance.getContainer(true).dashboards; let i = index" value="{{ i }}"><span *ngIf="dashboard.title">{{ dashboard.title }}</span><span *ngIf="!dashboard.title">Dashboard {{ i + 1 }}</span></option>
          </select>
      </form>
    </div>
    <div *ngIf="!instance.getContainer(true).dashboards">
      &nbsp;
    </div>
</div>

<div *ngIf="!instance.getContainer(true).dashboards" class="uk-alert-warning uk-animation-slide-top-small" uk-alert>
    <a class="uk-alert-close" uk-close></a>
    <p>No hay ningún dashboard asignado para esta instancia.</p>
</div>
<div *ngIf="instance.getContainer(true).dashboards && (!instance.getContainer(true).dashboards[getSelected()].url || !instance.getContainer(true).dashboards[getSelected()].width && instance.getContainer(true).dashboards[getSelected()].height)" class="uk-alert-warning uk-animation-slide-top-small" uk-alert>
    <a class="uk-alert-close" uk-close></a>
    <p>Esta instancia requiere ser configurada.</p>
</div>

<iframe *ngIf="instance.getContainer(true).dashboards && (instance.getContainer(true).dashboards[getSelected()].url && instance.getContainer(true).dashboards[getSelected()].width && instance.getContainer(true).dashboards[getSelected()].height)" class="control" [width]="instance.getContainer(true).dashboards[getSelected()].width" [height]="instance.getContainer(true).dashboards[getSelected()].height" [src]="instance.getContainer(true).dashboards[getSelected()].url|safe:'resourceUrl'" frameborder="0" style="border:0" allowfullscreen></iframe>

<div id="edit" class="uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <h2 class="uk-modal-title">Editar Dashboard</h2>

    <form (ngSubmit)="editDashboard(f)" #f="ngForm">
      <fieldset class="uk-fieldset">
        <div class="uk-margin">
          <input type="text" id="title" name="title" [(ngModel)]="instance.getContainer(true).dashboards[getSelected()].title" placeholder="Título" class="uk-input" required>
        </div>
        <div class="uk-margin">
          <input type="text" id="url" name="url" [(ngModel)]="instance.getContainer(true).dashboards[getSelected()].url" placeholder="URL" class="uk-input" required>
        </div>
        <div class="uk-margin">
          <input type="text" id="width" name="width" [(ngModel)]="instance.getContainer(true).dashboards[getSelected()].width" placeholder="Ancho" class="uk-input" required>
        </div>
        <div class="uk-margin">
          <input type="text" id="height" name="height" [(ngModel)]="instance.getContainer(true).dashboards[getSelected()].height" placeholder="Alto" class="uk-input" required>
        </div>

        <button class="uk-button uk-button-primary">Guardar cambios</button>
      </fieldset>
    </form>
  </div>
</div>

<div id="delete" class="uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <h2 class="uk-modal-title">Eliminar dashboard</h2>

    <p *ngIf="!deleted">Estas a punto de eliminar el dashboard<strong *ngIf="instance.getContainer(true).dashboards[getSelected()].title"> "{{ instance.getContainer(true).dashboards[getSelected()].title }}"</strong>. Esta acción no se puede revertir.<p>
    <p *ngIf="deleted">El dashboard fue eliminado.</p>

    <form *ngIf="!deleted" (ngSubmit)="deleteDashboard(false)" #f="ngForm">
      <fieldset class="uk-fieldset">
        <button class="uk-button uk-button-primary">Eliminar dashboard</button>
      </fieldset>
    </form>
  </div>
</div>
