<app-topbar></app-topbar>

<div *ngIf="instance.getUser()" class="uk-container">
  <div uk-grid>
      <div class="uk-width-1-4@m sidebar">
          <div>

            <div class="uk-flex uk-flex-column">
              <h3 *ngIf="instance.getContainer(true)">{{ instance.getContainer(true).name }}</h3>
              <ul *ngIf="instance.getContainer(true)" class="uk-nav uk-nav-primary">
                <li *ngIf="instance.getContainer(true).dashboards || instance.getUser().role == 'admin'" [className]="getSection() == 'dashboard' ? 'uk-parent uk-active' : 'uk-parent'">
                  <a (click)="selectSection('dashboard')"><span class="uk-margin-small-right" uk-icon="icon: grid"></span> Dashboard</a>
                </li>

                <li *ngIf="!(checkDenegate('leads') || checkHide('leads'))" [className]="getSection() == 'leads' ? 'uk-parent uk-active' : 'uk-parent'">
                  <a (click)="selectSection('leads')"><span class="uk-margin-small-right" uk-icon="icon: list"></span> Contactos</a>
                </li>
                <li *ngIf="instance.getUser().role == 'admin'" [className]="getSection() == 'config' ? 'uk-parent uk-margin-top uk-active' : 'uk-parent uk-margin-top'">
                  <a (click)="selectSection('config')"><span class="uk-margin-small-right" uk-icon="icon: cog"></span> Configuración</a>
                </li>

              </ul>
              <ul *ngIf="instance.getUser().role == 'admin'" class="uk-nav uk-nav-primary">
                <li *ngIf="instance.getContainer()" class="uk-nav-divider uk-margin-medium-top"></li>
                <li [className]="getSection() == 'control' ? 'uk-parent uk-active' : 'uk-parent'"><a (click)="selectSection('control')"><span class="uk-margin-small-right" uk-icon="icon: album"></span> Panel</a></li>
                <li [className]="getSection() == 'containers' ? 'uk-parent uk-active' : 'uk-parent'"><a (click)="selectSection('containers')"><span class="uk-margin-small-right" uk-icon="icon: thumbnails"></span> Contenedores</a></li>
              </ul>
            </div>

          </div>
      </div>
      <div class="uk-width-expand@m">
        <div *ngIf="getSection() == 'containers'">
          <app-containers></app-containers>
        </div>
        <div *ngIf="getSection() == 'leads'">
          <app-leads></app-leads>
        </div>
        <div *ngIf="getSection() == 'config'">
          <app-config></app-config>
        </div>
        <div *ngIf="getSection() == 'dashboard'">
          <app-dashboard></app-dashboard>
        </div>
        <div *ngIf="getSection() == 'control' && instance.getUser().role == 'admin'">
          <iframe class="control" width="900" height="900" src="https://datastudio.google.com/embed/reporting/7523245a-e961-4983-9e6f-64748b654960/page/4u1UB" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
  </div>
</div>
