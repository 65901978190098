import { Injectable } from '@angular/core';
import { InstanceService } from '../services/instance.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private itemDoc: AngularFirestoreDocument<any>;
  item: any;

  private schema;
  private key;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    public instance: InstanceService
  ) { }

  initConfig () {
    this.schema = null;
    this.key = null;

    if (this.instance.getContainer()) {
      this.itemDoc = this.afs.doc('containers/' + this.instance.getContainer() + '/config/api');
      this.item = this.itemDoc.valueChanges()
        .subscribe(data=>{
          if (data.key_platform) {
            this.key = data.key_platform;
            let url = 'https://api.topic.com.ar/v1/platform?schema=' + this.instance.getContainer() + '&key=' + this.key;

            this.http.get<any>(url).subscribe(schema => {
                this.schema = schema;
            })
          }
        }, (error) => {
          console.log(error);
      });
    }
  }

  getSchema () {
    return this.schema;
  }

  updateSchema () {
    let url = 'https://api.topic.com.ar/v1/platform?schema=' + this.instance.getContainer() + '&key=' + this.key;

    console.log(url,this.schema);

    this.http.post<any>(url, JSON.stringify(this.schema)).subscribe(data => {
      console.log('Response:',data);
    })
  }
}
