<div *ngIf="!getSchema()">
  <p>Este contenedor no posee un archivo de configuración.</p>
</div>

<div *ngIf="getSchema()">
  <ul uk-tab>
    <li [className]="getSection() == 'ui' ? 'uk-active' : ''"><a (click)="setSection('ui')">UI</a></li>
    <li [className]="getSection() == 'json' ? 'uk-active' : ''"><a (click)="setSection('json')">JSON</a></li>
  </ul>

  <div *ngIf="getSection() == 'ui'">
    <form class="uk-form-stacked" (ngSubmit)="saveSchema(f)" #f="ngForm">
      <div class="uk-margin">
        <label class="uk-form-label" for="container">Container</label>
        <div class="uk-form-controls">
          <input type="text" id="container" name="container" [(ngModel)]="getSchema().container" class="uk-input" required disabled>
        </div>
      </div>

      <div *ngIf="showItem(getSchema().key)" class="uk-margin">
        <label class="uk-form-label" for="key">Key</label>
        <div class="uk-form-controls">
          <input type="text" id="key" name="key" [(ngModel)]="getSchema().key" class="uk-input" minlength="12" required>
        </div>
        <div *ngIf="f.submitted && f.controls['key'].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
          <p><small>La key debe tener al menos 12 caracteres.</small></p>
        </div>
      </div>

      <h3 class="uk-heading-divider">Integraciones</h3>

      <ul uk-tab>
        <li [className]="getIntegrationTab() == 'send' ? 'uk-active' : ''"><a (click)="setIntegrationTab('send')" title="Envío">Envío de datos</a></li>
        <li [className]="getIntegrationTab() == 'get' ? 'uk-active' : ''"><a (click)="setIntegrationTab('get')" title="Recepción">Recepción de datos</a></li>
        <li [className]="getIntegrationTab() == 'datastudio' ? 'uk-active' : ''"><a (click)="setIntegrationTab('datastudio')" title="Data Studio">Data Studio</a></li>
      </ul>

      <div *ngFor="let tab of ['send','get','datastudio']">
        <div *ngIf="getIntegrationTab() == tab" class="uk-child-width-1-3@m" uk-grid>
          <div *ngFor="let integration of integrations[tab]">
            <div class="uk-card uk-card-default uk-card-small">
              <div class="uk-card-media-top">
                <img [src]="integration.id ? ('assets/integrations/profile/' + integration.id + '.webp') : 'assets/integrations/profile/default.webp'" alt="">
              </div>
              <div class="uk-card-body">
                <h3 class="uk-card-title">{{ integration.title }}</h3>
              </div>
              <div class="uk-card-footer">
                <div *ngIf="integration.id && !integration.disabled" >
                  <a href="#{{ integration.id }}" class="uk-button uk-button-text" uk-toggle>Configurar</a> <span *ngIf="getSchema().integrations[integration.active]" class="uk-text-success" uk-icon="icon: check"></span>
                </div>
                <div *ngIf="!integration.id || integration.disabled" >
                  <small>PRÓXIMAMENTE</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="email" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/email.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Email</h1>
              <p>Envia una notificación por email luego de que se registre una conversión.</p>
              <div>
                <div *ngIf="showItem(getSchema().integrations.emails)">
                  <div *ngFor="let item of getKeys(getSchema().integrations.emails); index as i;">
                    <h5 class="uk-heading-line"><span>Dataset: <strong class="uk-text-primary">{{ item }}</strong></span></h5>

                    <div *ngIf="showItem(getSchema().integrations.emails[item].direction)" class="uk-margin">
                      <label class="uk-form-label" for="email_direction_{{i}}">Correo electrónico</label>
                      <div class="uk-form-controls">
                        <input type="text" id="email_direction_{{i}}" name="email_direction_{{i}}" [(ngModel)]="getSchema().integrations.emails[item].direction" class="uk-input" minlength="5" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['email_direction_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>El email debe tener al menos 5 caracteres.</small></p>
                      </div>
                    </div>

                    <div class="uk-margin">
                      <ul class="uk-iconnav uk-margin">
                        <li><a (click)="deleteIntegration('emails', item)" uk-icon="icon: minus-circle"></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <ul class="uk-iconnav uk-margin">
                    <li><a (click)="selectConfiguration('emails')" href="#add" uk-icon="icon: plus-circle" uk-toggle></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="google_analytics" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/google_analytics.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Google Analytics</h1>
              <p>Registra el evento de conversión en Google Analytics.</p>
              <div>
                <div *ngIf="showItem(getSchema().integrations.googleanalytics)">
                  <div *ngIf="showItem(getSchema().integrations.googleanalytics.uid)" class="uk-margin">
                    <label class="uk-form-label" for="googleanalytics_uid">UID</label>
                    <div class="uk-form-controls">
                      <input type="text" id="googleanalytics_uid" name="googleanalytics_uid" [(ngModel)]="getSchema().integrations.googleanalytics.uid" class="uk-input" minlength="12" required>
                    </div>
                    <div *ngIf="f.submitted && f.controls['googleanalytics_uid'].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                      <p><small>El UID debe tener al menos 12 caracteres.</small></p>
                    </div>
                  </div>

                  <div *ngIf="showItem(getSchema().integrations.googleanalytics.tag.category)" class="uk-margin">
                    <label class="uk-form-label" for="googleanalytics_tag_category">Categoría</label>
                    <div class="uk-form-controls">
                      <input type="text" id="googleanalytics_tag_category" name="googleanalytics_tag_category" [(ngModel)]="getSchema().integrations.googleanalytics.tag.category" class="uk-input">
                    </div>
                  </div>

                  <div *ngIf="showItem(getSchema().integrations.googleanalytics.tag.action)" class="uk-margin">
                    <label class="uk-form-label" for="googleanalytics_tag_action">Acción</label>
                    <div class="uk-form-controls">
                      <input type="text" id="googleanalytics_tag_action" name="googleanalytics_tag_action" [(ngModel)]="getSchema().integrations.googleanalytics.tag.action" class="uk-input">
                    </div>
                  </div>

                  <div *ngIf="showItem(getSchema().integrations.googleanalytics.tag.label)" class="uk-margin">
                    <label class="uk-form-label" for="googleanalytics_tag_label">Etiqueta</label>
                    <div class="uk-form-controls">
                      <input type="text" id="googleanalytics_tag_label" name="googleanalytics_tag_label" [(ngModel)]="getSchema().integrations.googleanalytics.tag.label" class="uk-input">
                    </div>
                  </div>

                  <div class="uk-margin">
                    <ul class="uk-iconnav uk-margin">
                      <li><a (click)="deleteIntegration('googleanalytics')" uk-icon="icon: minus-circle"></a></li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="!showItem(getSchema().integrations.googleanalytics)" class="uk-margin">
                  <ul class="uk-iconnav uk-margin">
                    <li><a (click)="addDataset('googleanalytics')" uk-icon="icon: plus-circle"></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="doppler" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/doppler.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Doppler</h1>
              <p>Añade al contacto en una lista de Doppler.</p>
              <div>
                <div *ngIf="showItem(getSchema().integrations.doppler)">
                  <div *ngFor="let item of getKeys(getSchema().integrations.doppler); index as i;">
                    <h5 class="uk-heading-line"><span>Dataset: <strong class="uk-text-primary">{{ item }}</strong></span></h5>

                    <div *ngIf="showItem(getSchema().integrations.doppler[item].account)" class="uk-margin">
                      <label class="uk-form-label" for="doppler_account_{{i}}">Cuenta</label>
                      <div class="uk-form-controls">
                        <input type="text" id="doppler_account_{{i}}" name="doppler_account_{{i}}" [(ngModel)]="getSchema().integrations.doppler[item].account" class="uk-input" minlength="3" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['doppler_account_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La cuenta debe tener al menos 3 caracteres.</small></p>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.doppler[item].list)" class="uk-margin">
                      <label class="uk-form-label" for="doppler_list_{{i}}">Lista</label>
                      <div class="uk-form-controls">
                        <input type="text" id="doppler_list_{{i}}" name="doppler_list_{{i}}" [(ngModel)]="getSchema().integrations.doppler[item].list" class="uk-input" minlength="3" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['doppler_list_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La lista debe tener al menos 3 caracteres.</small></p>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.doppler[item].key)" class="uk-margin">
                      <label class="uk-form-label" for="doppler_key_{{i}}">Key</label>
                      <div class="uk-form-controls">
                        <input type="password" id="doppler_key_{{i}}" name="doppler_key_{{i}}" [(ngModel)]="getSchema().integrations.doppler[item].key" class="uk-input" minlength="3" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['doppler_key_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La key debe tener al menos 3 caracteres.</small></p>
                      </div>
                    </div>

                    <div class="uk-margin">
                      <ul class="uk-iconnav uk-margin">
                        <li><a (click)="deleteIntegration('doppler', item)" uk-icon="icon: minus-circle"></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <ul class="uk-iconnav uk-margin">
                    <li><a (click)="selectConfiguration('doppler')" href="#add" uk-icon="icon: plus-circle" uk-toggle></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tecnom" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/tecnom.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Tecnom</h1>
              <p>Añade el lead dentro del CRM de Tecnom.</p>
              <div>
                <div *ngIf="showItem(getSchema().integrations.tecnom)">
                  <div *ngFor="let item of getKeys(getSchema().integrations.tecnom); index as i;">
                    <h5 class="uk-heading-line"><span>Dataset: <strong class="uk-text-primary">{{ item }}</strong></span></h5>

                    <div *ngIf="showItem(getSchema().integrations.tecnom[item].subdomain)" class="uk-margin">
                      <label class="uk-form-label" for="tecnom_subdomain_{{i}}">Subdominio</label>
                      <div class="uk-form-controls">
                        <input type="text" id="tecnom_subdomain_{{i}}" name="tecnom_subdomain_{{i}}" [(ngModel)]="getSchema().integrations.tecnom[item].subdomain" class="uk-input" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['tecnom_subdomain_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>El subdominio es requerido.</small></p>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.tecnom[item].module)" class="uk-margin">
                      <label class="uk-form-label" for="tecnom_module_{{i}}">Módulo</label>
                      <div class="uk-form-controls">
                        <input type="text" id="tecnom_module_{{i}}" name="tecnom_module_{{i}}" [(ngModel)]="getSchema().integrations.tecnom[item].module" class="uk-input">
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.tecnom[item].auth.user)" class="uk-margin">
                      <label class="uk-form-label" for="tecnom_user_{{i}}">Usuario</label>
                      <div class="uk-form-controls">
                        <input type="text" id="tecnom_user_{{i}}" name="tecnom_user_{{i}}" [(ngModel)]="getSchema().integrations.tecnom[item].auth.user" class="uk-input" minlength="3" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['tecnom_user_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>El usuario debe tener al menos 3 caracteres.</small></p>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.tecnom[item].auth.password)" class="uk-margin">
                      <label class="uk-form-label" for="tecnom_pass_{{i}}">Contraseña</label>
                      <div class="uk-form-controls">
                        <input type="password" id="tecnom_pass_{{i}}" name="tecnom_pass_{{i}}" [(ngModel)]="getSchema().integrations.tecnom[item].auth.password" class="uk-input" minlength="3" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['tecnom_pass_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La contraseña debe tener al menos 3 caracteres.</small></p>
                      </div>
                    </div>

                    <div class="uk-margin">
                      <ul class="uk-iconnav uk-margin">
                        <li><a (click)="deleteIntegration('tecnom', item)" uk-icon="icon: minus-circle"></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <ul class="uk-iconnav uk-margin">
                    <li><a (click)="selectConfiguration('tecnom')" href="#add" uk-icon="icon: plus-circle" uk-toggle></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="facebook_leads" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/facebook.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Facebook Leads</h1>
              <p>Conecta una página de Facebook y recibe los leads provenientes de los formularios de clientes potenciales. Es necesario conectar una página y asignar los permisos requeridos para garantizar el acceso a los leads.</p>
              <div>
                <a href="https://api.topic.com.ar/v1/subscribe/facebook/index?schema={{ instance.getContainer(false) }}" target="_blank" title="Conectar" class="uk-button uk-button-primary" >Conectar página</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="datastudio_facebook" class="uk-modal-full" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
          <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
            <div class="uk-background-cover" style="background-image: url('assets/integrations/banner/facebook.webp');" uk-height-viewport></div>
            <div class="uk-padding-large">
              <h1>Facebook Insights</h1>
              <p>Permite conectar una fuente de datos de Facebook con Data Studio. Es necesario conectar una página y asignar los permisos requeridos para garantizar el acceso a la información.</p>
              <div>
                <p uk-margin>
                  <a href="https://api.topic.com.ar/v1/subscribe/facebook/index?schema={{ instance.getContainer(false) }}" target="_blank" title="Conectar" class="uk-button uk-button-primary uk-margin-right" >Conectar página</a>
                  <a href="https://datastudio.google.com/datasources/create?connectorId=AKfycbxqW9OCllIO2HbO4bfJAAWEl2imFBCnxGw6_LEstJxf9uFPAWxujP2XO_k_OENKgiSE" target="_blank" title="Conectar" class="uk-button uk-button-primary" >Conectar Data Studio</a>
                </p>

                <div *ngIf="showItem(getSchema().integrations.datastudio_facebook)">
                  <div *ngFor="let item of getKeys(getSchema().integrations.datastudio_facebook); index as i;">
                    <h5 class="uk-heading-line"><span>ID (Secreto): <strong class="uk-text-primary">{{ item }}</strong></span></h5>

                    <div *ngIf="showItem(getSchema().integrations.datastudio_facebook[item].connector)" class="uk-margin">
                      <label class="uk-form-label" for="datastudio_facebook_connector_{{i}}">Conector</label>
                      <div class="uk-form-controls">
                        <select id="datastudio_facebook_connector_{{i}}" name="datastudio_facebook_connector_{{i}}" [(ngModel)]="getSchema().integrations.datastudio_facebook[item].connector" class="uk-select" required>
                          <option value="campaigns">Campañas</option>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.datastudio_facebook[item].ad_account)" class="uk-margin">
                      <label class="uk-form-label" for="datastudio_facebook_adaccount_{{i}}">Ad Account ID</label>
                      <div class="uk-form-controls">
                        <input type="text" id="datastudio_facebook_adaccount_{{i}}" name="email_direction_{{i}}" [(ngModel)]="getSchema().integrations.datastudio_facebook[item].ad_account" class="uk-input" minlength="5" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['datastudio_facebook_adaccount_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La ID debe tener al menos 5 caracteres.</small></p>
                      </div>
                    </div>

                    <div *ngIf="showItem(getSchema().integrations.datastudio_facebook[item].page)" class="uk-margin">
                      <label class="uk-form-label" for="datastudio_facebook_page_{{i}}">Page ID</label>
                      <div class="uk-form-controls">
                        <input type="text" id="datastudio_facebook_page_{{i}}" name="email_direction_{{i}}" [(ngModel)]="getSchema().integrations.datastudio_facebook[item].page" class="uk-input" minlength="5" required>
                      </div>
                      <div *ngIf="f.submitted && f.controls['datastudio_facebook_page_' + i].errors" class="uk-alert-danger uk-animation-shake" uk-alert>
                        <p><small>La ID debe tener al menos 5 caracteres.</small></p>
                      </div>
                    </div>

                    <div class="uk-margin">
                      <ul class="uk-iconnav uk-margin">
                        <li><a (click)="deleteIntegration('datastudio_facebook', item)" uk-icon="icon: minus-circle"></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <ul class="uk-iconnav uk-margin">
                    <li><a (click)="selectConfiguration('datastudio_facebook')" href="#add" uk-icon="icon: plus-circle" uk-toggle></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-margin uk-margin-medium-top">
        <button [className]="!f.invalid && f.dirty ? 'uk-button uk-button-primary' : 'uk-button uk-button-muted'">Guardar</button>
      </div>
    </form>
  </div>

  <div *ngIf="getSection() == 'json'">
    <div class="uk-alert-success uk-animation-slide-top-small" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Esta vista refleja el archivo JSON utilizado para configurar el esquema de datos.</p>
    </div>
    <div class="uk-background-muted uk-padding-small"><code class="code">{{ getSchema()|json }}</code></div>
  </div>

  <div id="add" class="uk-flex-top" uk-modal="stack: true">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <h2 class="uk-modal-title">Nuevo campo</h2>

      <form (ngSubmit)="selectDataset(f)" #f="ngForm">
        <fieldset class="uk-fieldset">
          <div class="uk-margin">
            <input type="text" id="dataset" name="dataset" [(ngModel)]="dataset" placeholder="Nombre" class="uk-input" required>
          </div>

          <button class="uk-button uk-button-primary">Añadir</button>
        </fieldset>
      </form>
    </div>
  </div>
</div>
