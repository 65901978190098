import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InstanceService } from '../../services/instance.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  private selected = 0;
  deleted = false;

  constructor(
    public instance: InstanceService
  ) { }

  ngOnInit(): void {
  }

  getSelected () {
    return this.selected;
  }

  setSelected (index:number) {
    this.selected = index;
  }

  addDashboard () {
    this.instance.addDashboard(
      {
        title: 'Nuevo dashboard',
        url: null,
        width: null,
        height: null
      }
    );
    this.selected = this.instance.getContainer(true).dashboards.length - 1;
  }

  editDashboard (form: NgForm) {
    this.instance.updateContainer();
  }

  deleteDashboard (refresh:boolean) {
    if (refresh) {
      this.deleted = false;
    } else {
      this.instance.getContainer(true).dashboards.splice(this.selected,1);
      this.instance.updateContainer();
      this.deleted = true;
      this.selected = 0;
    }

  }

}
