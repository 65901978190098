import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  user: UserModel = new UserModel();

  error:string;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    this.route.params.subscribe(params => {
        this.error = params['error'];
    });
  }

  ngOnInit(): void {

  }

  login(form: NgForm) {
    if (form.invalid) {
      return;
    }

    return this.auth.login(this.user);
  }

  isLogged() {
    return this.auth.isAuthenticated();
  }

  getError() {
    return this.error;
  }

}
